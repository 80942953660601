import React from 'react'
import fourOfour from '../../public/fourOfour.png'
//add a link to go back to the Home component when you set this up

export default class PageNotFound extends React.Component {

    render() {
        return (
            <div id="pageNotFoundContainer">
                <img id="pageNotFound" src={fourOfour} />
            </div>
        )
    }
}

