import React from 'react'
import { gsap } from "gsap";
import {ChooseSongSVG} from './Logos/ChooseSongSVG'
const { useRef, useEffect} = React;


export const ChooseSong = (props) => {

    let circles = useRef([])
    let circles2 = useRef([])

    useEffect(() => {

        gsap.fromTo(circles.current, 3, {scale: 0}, {autoAlpha: 0, scale: 1, stagger: 1, repeat: -1});
        gsap.fromTo(circles2.current, 3, {scale: 0}, {autoAlpha: 0, scale: 1, stagger: 1, repeat: -1, delay: 3});

      }, []);


    return (
        <div id="chooseSongWrapper" >
            <div id="chooseSong">
                <div className="bullseye" style={{backgroundColor: '#D60404'}} ref={element => {circles.current[0] = element;}} />
                <div className="bullseye" style={{backgroundColor: '#FFD91B'}} ref={element => {circles.current[1] = element;}} />
                <div className="bullseye" style={{backgroundColor: '#500482'}} ref={element => {circles.current[2] = element;}} />

                <div className="bullseye" style={{backgroundColor: '#D60404'}} ref={element => {circles2.current[0] = element;}} />
                <div className="bullseye" style={{backgroundColor: '#FFD91B'}} ref={element => {circles2.current[1] = element;}} />
                <div className="bullseye" style={{backgroundColor: '#500482'}} ref={element => {circles2.current[2] = element;}} />
                <div id="chooseSongSVGContainer">
                    <ChooseSongSVG colorA="#1F1F1F" colorB="white" />
                </div>
            </div>
        </div>
    )
}
