const axios = require('axios')



export const search = async (query, accessToken) => {
    try {
        const q = encodeURIComponent(query);
        const response = await fetch(
          `https://api.spotify.com/v1/search?type=track&limit=1&market=US&q=${q}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        const searchJSON = await response.json();

        let uriResponse
        if (searchJSON.tracks.items[0]) {

            uriResponse = searchJSON.tracks.items[0].uri

        }
        else {
            uriResponse = false
        }


        return uriResponse

    }
    catch (e){
        console.log(e)
    }

  };

  export const play = async (uri, accessToken, deviceID) => {

    try {

        const response = await fetch('https://api.spotify.com/v1/me/player/play?device_id=' + deviceID, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({uris: [uri]})
        })

        if (response.statusText === 'Unauthorized'){
          let {data} = await axios.get('api/spotify/timeout')
          window.location.href = data
        }

    }
    catch (e){
        console.log('this is play error', e)
    }

  }

  export const getPlaylists = async (accessToken) => {
    try {
      const userPlaylists = await fetch('https://api.spotify.com/v1/me/playlists?limit=50', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })

      if (userPlaylists.statusText === 'Unauthorized'){
        // window.location.replace("http://localhost:3000/timeout")
        axios.get('api/spotify/timeout')
      }

      const userPlaylistJSON = userPlaylists.json()
      return userPlaylistJSON
    }
    catch (e){
      console.log('getPlaylists error', e)
    }
  }

  export const createPlaylist = async (accessToken, user_id) => {
    try {

      const playlist = await fetch(`https://api.spotify.com/v1/users/${user_id}/playlists`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: 'Chart Explorer'
          })
      })

      if (playlist.statusText === 'Unauthorized'){
        let {data} = await axios.get('api/spotify/timeout')
        window.location.href = data
      }

      const playlistJSON = playlist.json()

      return playlistJSON
    }
    catch (e){
        console.log('this is createPlaylist error', e)
    }
  }

  export const addSongToPlaylist = async (uri, playlistID, accessToken) => {
    try {
      let response = await fetch(`https://api.spotify.com/v1/playlists/${playlistID}/tracks`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({uris: [uri]})
      })

      if (response.statusText === 'Unauthorized'){
        let {data} = await axios.get('api/spotify/timeout')
        window.location.href = data
      }

    }
    catch (e){
        console.log('this is addSongToPlaylist error', e)
    }
  }

  export const savePlaylist = async (accessToken, user_id, playlistName, uriArray) => {
    try {

      const playlist = await fetch(`https://api.spotify.com/v1/users/${user_id}/playlists`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: playlistName
          })
      })

      if (playlist.statusText === 'Unauthorized'){
        let {data} = await axios.get('api/spotify/timeout')
        window.location.href = data
      }

      const playlistJSON = await playlist.json()

      let response = await fetch(`https://api.spotify.com/v1/playlists/${playlistJSON.id}/tracks`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({uris: uriArray})
    })


    if (response.statusText === 'Unauthorized'){
      let {data} = await axios.get('api/spotify/timeout')
      window.location.href = data
    }

    }
    catch (e){
        console.log('this is createPlaylist error', e)
    }
  }
