import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import LogIn from './components/LogIn'
import ChartView from './components/ChartView'
import PageNotFound from './components/PageNotFound'
import Timeout from './components/Timeout'
import "../static/styles.css"


class App extends React.Component {


    render() {
        return (
          <Router>
            <Switch>
              <Route exact path="/" component={LogIn} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/chart/:chart/:week" component={ChartView} />
              <Route exact path="/timeout" component={Timeout} />
              <Route path="/404" component={PageNotFound} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </Router>
        )
    }
}

let mountNode = document.getElementById('app');

ReactDOM.render(<App />, mountNode);

