import React from 'react'
import { gsap } from "gsap";
const { useRef, useEffect} = React;


export const Loading = (props) => {

    let circles = useRef([])

    useEffect(() => {

        gsap.fromTo(circles.current, 1, {scale: 0}, {autoAlpha: 0, scale: 1, stagger: .5, repeat: -1}, 0);

      }, []);


    return (
    <div id="loadingContainer" >
        <div className="loading" style={{backgroundColor: '#D60404'}} ref={element => {circles.current[0] = element;}} />
        <div className="loading" style={{backgroundColor: '#FFD91B'}} ref={element => {circles.current[1] = element;}} />
        <div className="loading" style={{backgroundColor: '#500482'}} ref={element => {circles.current[2] = element;}} />
    </div>
    )
}
