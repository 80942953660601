import React from 'react'
import {PlayIcon} from './Icons/PlayIcon.js'

export const SongCard = (props) => {
    
    let prevWeek;
    props.song.previousWeekPosition === null ? prevWeek = '-' : prevWeek = props.song.previousWeekPosition

      return (
        <div id="songCardWrapper">
            <div id="songCard">
                <div id="songRankWrapper">
                    <div
                    id="songRank"
                    onClick={
                    () => {
                      if (props.isAdmin){
                          props.replaceURI(props.song)
                      }
                    }
                    }
                    >
                        {
                        props.chart === 'Birthday Toppers' ?
                        <h1 className="songcardBirthdayFont">{props.song.week.slice(0, 4)}</h1>
                        :
                        <h1 className="songcardRankFont">{props.song.weekPosition}</h1>
                        }
                    </div>
                </div>
                <div id="songInfo">
                        <h4>{props.song.song}</h4>
                        <h5>{props.song.artist}</h5>
                        <h6>Previous Week: {prevWeek}&nbsp;&nbsp;&nbsp;Weeks On Chart: {props.song.weeksOnChart}</h6>
                </div>
                <div id="songPlayPause">
                         {
                        props.song.spotifyURI !== 'none' ?
                        <button
                        className="songCardIcon"
                        onClick={() => {
                        props.handlePlay(
                        props.song,
                        props.accessToken,
                        props.deviceID,
                        props.index,
                        )}}
                        >
                        <PlayIcon color="#723799" />
                        </button>
                        :
                        null
                        }
                </div>
            </div>
        </div>
      )
}
