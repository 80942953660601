//THE PURPOSE OF THIS FUNCTION IS TO GENERATE A BILLBOARD WEEK FROM ANY GIVEN DATE

//58-61 chart is set on monday
//62 chart is set on saturday
//the chart date needs to be the one in front of the selected date unless it is equal to it

//ONE FUNCTION FOR MONDAYS
//ONE FUNCTION FOR SATURDAYS

//use new Date() in order to solve deprecation warning

let moment = require('moment')
const mondayYears = ['1958', '1959', '1960', '1961']

//format as MM-DD-YYYY
const dateLogic = (date) => {

    let chartDate;
    let formatedChartDate;
    let userDate = moment(date, 'MM-DD-YYYY').format('MM-DD-YYYY')
    let userYear = moment(date, 'MM-DD-YYYY').format('MM-DD-YYYY').slice(-4)
    let userDay = Number(moment(date, 'MM-DD-YYYY').format('MM-DD-YYYY').slice(3, 5))
    let userMonth = Number(moment(date, 'MM-DD-YYYY').format('MM-DD-YYYY').slice(0, 2))

    // if before december 31 1961

    if (mondayYears.includes(userYear)){

        let week = moment(userDate, 'MM-DD-YYYY').week()
        let year = moment(userDate, 'MM-DD-YYYY').year()
        chartDate = moment().year(year).week(week).day('Monday').add(1, 'week')
        formatedChartDate = moment(new Date(chartDate)).format('YYYY-MM-DD')

    }
    else {

        let week = moment(userDate, 'MM-DD-YYYY').week()
        let year = moment(userDate, 'MM-DD-YYYY').year()
        chartDate = moment().year(year).week(week).day('Saturday')
        formatedChartDate = moment(new Date(chartDate)).format('YYYY-MM-DD')
    }

    return formatedChartDate;
}

const validDate = (date) => {
    let userDate = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
    let valid = moment(userDate).isBetween('1958-08-02', '2019-12-29')
    return valid
}

export {
    dateLogic,
    validDate
}

