import React from 'react'

export const PlayIcon = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    const styleA = {
        fill: props.color
    }

    const styleB = {
        fill: 'white'
    }

      return (
        <svg width="100%" height="100%" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1.15298,0,0,1.15298,-76.6604,-96.6673)">
                <circle cx="586.878" cy="604.231" r="501.981" style={styleA} />
            </g>
            <g transform="matrix(7.10422e-17,1.16021,-1.15376,7.06473e-17,1489.57,5.58897)">
                <path d="M466.128,444.534C474.909,426.972 492.785,415.89 512.332,415.89C531.878,415.89 549.755,426.972 558.535,444.534C628.886,585.235 761.521,850.504 826.413,980.289C834.473,996.41 833.648,1015.58 824.231,1030.94C814.814,1046.3 798.153,1055.65 780.209,1055.65L244.454,1055.65C226.51,1055.65 209.849,1046.3 200.433,1030.94C191.016,1015.58 190.19,996.41 198.251,980.289C263.143,850.504 395.778,585.235 466.128,444.534Z" style={styleB} />
            </g>
        </svg>
    )
}
