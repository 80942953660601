import React from 'react'

export const PauseIcon = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    const styleA = {
        fill: props.color
    }

    const styleB = {
        fill: 'white'
    }

      return (
        <svg width="100%" height="100%" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle} >
            <g transform="matrix(1.15298,0,0,1.15298,-76.6604,-96.6673)">
                <circle cx="586.878" cy="604.231" r="501.981" style={styleA} />
            </g>
            <g transform="matrix(0.954099,0,0,0.954099,25.9345,27.5408)">
                <path d="M552.338,237.551C552.338,204.414 525.475,177.551 492.338,177.552L362.938,177.552C329.801,177.551 302.938,204.414 302.938,237.551L302.938,962.449C302.938,995.586 329.801,1022.45 362.938,1022.45L492.338,1022.45C525.475,1022.45 552.338,995.586 552.338,962.449L552.338,237.551ZM651.029,962.449C651.029,995.586 677.892,1022.45 711.029,1022.45L840.429,1022.45C873.566,1022.45 900.429,995.586 900.429,962.449L900.429,237.551C900.429,204.414 873.566,177.551 840.429,177.552L711.029,177.552C677.892,177.551 651.029,204.414 651.029,237.551L651.029,962.449Z" style={styleB} />
            </g>
        </svg>

    )
}
