import React from 'react'
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom'
import {dateLogic, validDate} from '../../dateLogic'
import nav_arrow from '../../public/nav-arrow-black.png'
import {Logo2} from './Logos/Logo2'

let moment = require('moment')

// const accessToken = require('../../server/api/spotifyAuthentication')

import { gsap } from "gsap";

export default class Home extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      day: '',
      month: '',
      year: '',
      date: '',
      redirect: false,
      options: ['Billboard Hot 100', 'Birthday Toppers'],
      optionsIdx: 0
    }

    this.tl = gsap.timeline({paused: true})
    this.mm = null;
    this.dd = null;
    this.yyyy = null;
    this.form = null;
    this.chartOptions = null;
    this.tih = null;
    this.explore = null;
    this.accent1 = null;
    this.accent2 = null;
    this.accent3 = null;
    this.accent4 = null;


    this.handleDayMonthChange = this.handleDayMonthChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeOptions = this.handleChangeOptions.bind(this)
    this.thisDayInHistory = this.thisDayInHistory.bind(this)
  }

  componentDidMount(){

    let accents = [
      this.accent1,
      this.accent3,
      this.accent2,
      this.accent4
  ]

  let boxes = [
    this.mm,
    this.dd,
    this.yyyy
  ]


  this.tl.to(accents, 2, {height: '100%', ease: 'Power2.easeOut'}, 0)
  this.tl.to(this.form, 2, {height: '100%', padding: '1%', y: '0%', ease: 'Power2.easeOut'}, 1)
  this.tl.from(boxes, 2, {autoAlpha: 0, ease: 'Power2.easeOut'}, 2)
  this.tl.from(this.chartOptions, 1, {autoAlpha: 0, y: 10, ease: 'Power2.easeOut'}, 1)
  this.tl.from(this.tih, 1, {autoAlpha: 0, y: -10, ease: 'Power2.easeOut'}, 1)
  this.tl.from(this.explore, 1, {autoAlpha: 0, ease: 'Power2.easeOut'}, 1.25)
  this.tl.play()


  }

  handleDayMonthChange(event){

    let val = Number(event.target.value)

    if (isNaN(val)){
      console.log('not a num')
      return
    }

    if (val.toString().length === 2) {
        this.setState({
          [event.target.name]: val
        })
    }

    if (val.toString().length === 1){
      val = '0' + val

      this.setState({
        [event.target.name]: val
      })
    }
  }

  handleYearChange(event){

    let val = Number(event.target.value)

    if (isNaN(val)){
      console.log('not a num')
      return
    }

    if (val.toString().length === 4) {
        this.setState({
          [event.target.name]: val
        })
    }

    if (val.toString().length === 1){
      val = '000' + val
      this.setState({
        [event.target.name]: val
      })
    }
    if (val.toString().length === 2){
      val = '00' + val
      this.setState({
        [event.target.name]: val
      })
    }
    if (val.toString().length === 3){
      val = '0' + val
      this.setState({
        [event.target.name]: val
      })
    }
  }

  //add the date to url on submit
  handleSubmit(event){
    event.preventDefault()

    let submitDate;
    let chart = this.state.options[this.state.optionsIdx]
    
    //only need date Logic for ONE SPECFIC chart date
    if (chart === 'Billboard Hot 100'){
      submitDate = dateLogic(this.state.month + '-' + this.state.day + '-' + this.state.year)

      if (validDate(submitDate)){
        this.setState({date: submitDate})
        this.setState({redirect: true})
      }
      else {
        window.alert('Please Enter a Valid Date!')
      }
    }

    //don't need date logic for birthday
    // if (chart === 'Birthday Toppers'){
    //   submitDate = this.state.year + '-' + this.state.month + '-' + this.state.day
    //   this.setState({date: submitDate})
    //   this.setState({redirect: true})
    // }
    if (chart === 'Birthday Toppers'){

      submitDate = this.state.year + '-' + this.state.month + '-' + this.state.day
      if (validDate(submitDate)){
        this.setState({date: submitDate})
        this.setState({redirect: true})
      }
      else {
        window.alert('Please Enter a Valid Date!')
      }
    }

  }

  handleChangeOptions(event){
    let id = event.target.id
    let maxIdx = this.state.options.length - 1

    if (id === 'rightNavArrow' || id === 'right'){

      let increment = this.state.optionsIdx + 1
      increment > maxIdx ? this.setState({optionsIdx: 0}) : this.setState({optionsIdx: increment})

    }
    if (id === 'leftNavArrow' || id === 'left'){
      let decrement = this.state.optionsIdx - 1

      decrement < 0 ? this.setState({optionsIdx: maxIdx}) : this.setState({optionsIdx: decrement})
    }
  }

  thisDayInHistory(){

    let years = ['1959', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019']

    let day = moment().format('DD')
    let month = moment().format('MM')
    let randomNumber = Math.floor(Math.random() * years.length)

    let year = years[randomNumber]

    this.setState({day: day, month: month, year: year})

  }

  render() {

    let chart;

    this.state.options[this.state.optionsIdx] ? chart = this.state.options[this.state.optionsIdx] : chart = null

    const redirect = this.state.redirect

    if (redirect === true){

      let chartRouteFormat = chart.replace(/ /g, '_')
      let route = `/chart/${chartRouteFormat}/${this.state.date}`
      return <Redirect push to={route} />

    }
    else {
      return (

          <div id="homeComponentContainer">
            <div id="header">
              <div id="homeLogoContainer">
                <Logo2 colorA="white" colorB="#D60404" />
              </div>
            </div>
            <div id="dateFormContainer">

              <div id="chartOptions" ref={div => {this.chartOptions = div}} >
                <button id="left" className="arrowButton" onClick={this.handleChangeOptions}>
                  <img className="arrows" id="leftNavArrow" src={nav_arrow} />
                  </button>
                  <div id="chartSelectionWrapper">
                    <div id="chartSelection">
                      <h1>{chart}</h1>
                    </div>
                  </div>
                  <button id="right" className="arrowButton" onClick={this.handleChangeOptions}>
                    <img className="arrows" id="rightNavArrow" src={nav_arrow} />
                  </button>
              </div>
              <div id="dateSelector">
                <div id="dateAccentContainer">
                  <div className="dateAccent" id="da1" ref={div => {this.accent1 = div}} />
                  <div className="dateAccent" id="da2" ref={div => {this.accent2 = div}} />
                  <div className="dateAccent" id="da3" ref={div => {this.accent3 = div}} />
                  <div className="dateAccent" id="da4" ref={div => {this.accent4 = div}} />
                </div>
                <div id="dateFormWrapper" >
                  <form id="dateForm" onSubmit={this.handleSubmit} ref={div => {this.form = div}}>
                      <input
                        ref={input => {this.mm = input}}
                        id="mm"
                        className="mmDD"
                        name="month"
                        type="text"
                        value={this.state.month}
                        placeholder="MM"
                        onChange={this.handleDayMonthChange}
                        maxLength="3"
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            this.handleSubmit(event)
                          }
                        }}
                      />
                      <input
                      ref={input => {this.dd = input}}
                        id="dd"
                        className="mmDD"
                        name="day"
                        type="text"
                        value={this.state.day}
                        placeholder="DD"
                        onChange={this.handleDayMonthChange}
                        maxLength="3"
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            this.handleSubmit(event)
                          }
                        }}
                      />
                      <input
                      ref={input => {this.yyyy = input}}
                        id="YYYY"
                        name="year"
                        type="text"
                        value={this.state.year}
                        placeholder="YYYY"
                        onChange={this.handleYearChange}
                        maxLength="5"
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            this.handleSubmit(event)
                          }
                        }}
                      />
                  </form>
                </div>
              </div>
              <div id="historyContainer">
                <button onClick={this.thisDayInHistory} id="dayInHistoryButton" ref={button => {this.tih = button}}>
                  <h1>Today In History</h1>
                </button>
              </div>
            </div>
            <div id="dateSearch">
              <div id="searchButtonContainer">
                <button onClick={this.handleSubmit} id="searchButton" ref={button => {this.explore = button}}>
                  <h1>EXPLORE</h1>
                </button>
              </div>
            </div>
          </div>

      )
    }
  }
}
