import React from 'react'
import { gsap } from "gsap";
import unavailable from '../../public/album-art-unavailable.png'
import {PlayIcon} from './Icons/PlayIcon.js'
import {PauseIcon} from './Icons/PauseIcon.js'
import {SkipIcon} from './Icons/SkipIcon.js'
import {SaveIcon} from './Icons/SaveIcon.js'
import {ChooseSong} from './ChooseSong'
import {HeartIcon} from './Icons/HeartIcon'

export default class Player extends React.Component {

    constructor(props){
        super(props)
        
        this.controlAccent = null
        this.artAccent = null
        this.rank = null
        this.pause = null
        this.skip = null
        this.save = null
        this.chartInfo = null
        this.nowPlayingBox = null
        this.nowPlayingInfo = null
        this.add = null
        this.art = null
        this.artWrapper = null
        this.iconColor = '#723799'

        this.state = {
            artLoaded: false,
            animateOnce: false
        }
        this.handleArtLoad = this.handleArtLoad.bind(this)
      }


    componentDidUpdate(){

        if (this.state.artLoaded === true && this.state.animateOnce === false){

            const controlStagger = [this.rank, this.pause, this.skip, this.save]

            let tl = gsap.timeline({paused: true})

            //art
            //tl.from(this.art, 1, {scale: 1.25, autoAlpha: 0}, 0)
            tl.from(this.art, 1.5, {scale: 2, autoAlpha: 0}, 0)
            tl.to(this.artWrapper, 1.5, {overflow: 'visible'}, 0)
            
            //control panel
            tl.fromTo(this.controlAccent, 0.75, {height: '0%', autoAlpha: 0}, {height: '100%', autoAlpha: 1}, 0.5)
            tl.staggerFrom(controlStagger, 0.75, {scale: 0, autoAlpha: 0}, 0.1, 0.75)

            //art accent
            tl.fromTo(this.artAccent, 0.5, {width: '0%', autoAlpha: 0}, {width: '72%', autoAlpha: 1}, 1)
            tl.from(this.chartInfo, 0.5, {y: 5, autoAlpha: 0}, 1.5)

            //now playing
            tl.fromTo(this.nowPlayingBox, 0.5, {width: '0%', autoAlpha: 0}, {width: '100%', autoAlpha: 1}, 1.25)
            tl.from(this.nowPlayingInfo, 0.5, {y: 5, autoAlpha: 0}, 1.75)
            tl.from(this.add, 0.5, {scale: 0}, 1.75)

            tl.play()

            this.setState({animateOnce: true})
        }
    }

    handleArtLoad(){
        if (this.state.artLoaded === false){
            this.setState({artLoaded: true})
        }
    }

    render(){

        let art = null

        if (this.props.spotifyInfo !== null){
            if (Object.keys(this.props.spotifyInfo).length > 0){
                art = this.props.spotifyInfo.track_window.current_track.album.images[0].url
            }
        }
        else {
            art = unavailable
        }

      return (

            <div id="playerContainer">

            {

            this.props.initialized ?

            <div id="player" style={this.state.artLoaded ? {} : {display: 'none'}}>
                <div id="topPlayerContainer">
                    <div id="topPlayerAccent" ref={div => {this.artAccent = div}} />
                    <div id="artContainer">
                        <div id="artWrapper" ref={div => {this.artWrapper = div}}>
                        <img
                            src={art}
                            id="art"
                            ref={div => {this.art = div}}
                            onLoad={this.handleArtLoad}
                        />
                        </div>
                        <div id="playerPrev" ref={div => {this.chartInfo = div}}>
                            <h3>Previous Week: {this.props.chartInfo.previousWeekPosition}</h3>
                            <h3>Weeks on Chart: {this.props.chartInfo.weeksOnChart}</h3>
                        </div>
                    </div>
                    <div id="playerControlContainer">
                        <div id="playerControlAccent" ref={div => {this.controlAccent = div}} />
                        <div id="playerRank" ref={div => {this.rank = div}}>
                            {
                            this.props.chart === 'Birthday Toppers' ?
                            <h1 className="playerBirthdayFont">{this.props.chartInfo.week.slice(0, 4)}</h1>
                            :
                            <h1 className="playerRankFont">{this.props.chartInfo.weekPosition}</h1>
                            }
                        </div>

                        {
                            this.props.paused ?
                            <button className="playerContainerIcon" onClick={() => this.props.togglePlayback()}>
                                <PlayIcon color={this.iconColor} />
                            </button>
                            :
                            <button className="playerContainerIcon" ref={button => {this.pause = button}} onClick={() => this.props.togglePlayback()}>
                                <PauseIcon color={this.iconColor} />
                            </button>
                        }

                        <button className="playerContainerIcon" ref={button => {this.skip = button}} onClick={() => this.props.skip()} >
                          <SkipIcon color={this.iconColor} />
                        </button>

                        <button className="playerContainerIcon" ref={button => {this.save = button}} onClick={() => this.props.savePlaylist()} >
                          <SaveIcon color={this.iconColor} />
                        </button>
                    </div>
                </div>
                <div id="bottomPlayerContainer">
                    <div id="playerSongInfo" ref={div => {this.nowPlayingBox = div}}>
                        <div id="nowPlayingInfo" ref={div => {this.nowPlayingInfo = div}}>
                            <h1>{this.props.chartInfo.song}</h1>
                            <h2>{this.props.chartInfo.artist}</h2>
                        </div>
                        <div id="nowPlayingIconWrapper">
                        <button id="nowPlayingIcon" ref={div => {this.add = div}} onClick={() => this.props.addSong(this.props.chartInfo.spotifyURI)}>
                            <HeartIcon color={this.iconColor} />
                        </button>
                        </div>
                    </div>
                </div>
            </div>

            :

            <div id="chooseSongContainer">
                <ChooseSong />
            </div>

            }
            </div>
      )
    }
}
