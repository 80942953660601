import React from 'react'

export const HeartIcon = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    const styleA = {
        fill: props.color
    }

    const styleB = {
        fill: 'white'
    }

      return (
        <svg width="100%" height="100%" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1.15298,0,0,1.15298,-76.6604,-96.6673)">
                <circle cx="586.878" cy="604.231" r="501.981" style={styleA} />
            </g>
            <g transform="matrix(1.40121,0,0,1.05864,-275.477,-58.1128)">
                <path d="M624.802,515.976C679.665,376.285 789.39,376.285 844.254,432.162C899.119,488.038 899.119,599.791 844.254,711.544C805.849,795.358 707.096,879.173 624.802,935.049C542.507,879.173 443.754,795.358 405.35,711.544C350.487,599.791 350.487,488.038 405.35,432.162C460.213,376.285 569.939,376.285 624.802,515.976Z" style={styleB} />
            </g>
        </svg>
    )
}
