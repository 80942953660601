import React from 'react'

export const SkipIcon = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    const styleA = {
        fill: props.color
    }

    const styleB = {
        fill: 'white'
    }

      return (
        <svg width="100%" height="100%" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1.15298,0,0,1.15298,-76.6604,-96.6673)">
                <circle cx="586.878" cy="604.231" r="501.981" style={styleA} />
            </g>
            <g transform="matrix(1.066,0,0,1.066,-30.8379,-40.9283)">
                <path d="M759.426,517.46L759.426,354.759C759.426,338.846 765.747,323.585 777,312.333C788.252,301.081 803.513,294.759 819.426,294.759C839.019,294.759 860.772,294.759 880.365,294.759C896.278,294.759 911.539,301.081 922.791,312.333C934.044,323.585 940.365,338.846 940.365,354.759C940.365,472.041 940.365,730.448 940.365,847.73C940.365,863.643 934.044,878.904 922.791,890.156C911.539,901.408 896.278,907.73 880.365,907.73C860.772,907.73 839.019,907.73 819.426,907.73C803.513,907.73 788.252,901.408 777,890.156C765.747,878.904 759.426,863.643 759.426,847.73L759.426,682.54L330.192,898.823C311.592,908.195 289.462,907.251 271.728,896.328C253.994,885.406 243.193,866.068 243.193,845.241C243.193,714.898 243.193,485.102 243.193,354.759C243.193,333.932 253.994,314.594 271.728,303.672C289.462,292.749 311.592,291.805 330.192,301.177L759.426,517.46Z" style={styleB} />
            </g>
        </svg>
    )
}
