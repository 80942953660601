import React from 'react'
import { gsap } from "gsap";
import { Logo1 } from './Logos/Logo1'


export default class LogIn extends React.Component {

    constructor(props){
        super(props)

        this.minX = 0;
        this.maxX = window.innerWidth;

        this.minY = 0;
        this.maxY = window.innerHeight;

        this.minSize = 0.1;
        this.maxSize = 0.35;

        this.minDelay = 0;
        this.maxDelay = 3;

        this.minOpacity = 0.3;
        this.maxOpacity = 1;

        this.minDuration = 2;
        this.maxDuration = 4;

        this.tl = gsap.timeline({paused: true})
        this.logo = null;
        this.logoBG = null;
        this.cta = null;
        this.line1 = null;
        this.line2 = null;
        this.line3 = null;
        this.line4 = null;
        this.line5 = null;
        this.line6 = null;

        this.createCircles = this.createCircles.bind(this)
        this.animate = this.animate.bind(this)
        this.random = this.random.bind(this)
        this.randomColor = this.randomColor.bind(this)
    }
    
    componentDidMount(){

        let lines = [
            this.line1,
            this.line2,
            this.line3,
            this.line4,
            this.line5,
            this.line6,
        ]
        
        this.tl.to(this.logoBG, 0.25, {opacity: 1}, 1)
        this.tl.staggerTo(lines, 2, {width: "100%", xPercent: 0, ease: 'Power2.easeOut'}, 0.25, 0)
        this.tl.add(this.createCircles, 1)
        this.tl.from(this.logo, 2, {scale: 1.25, autoAlpha: 0, ease: 'Power2.easeOut'}, 1)
        this.tl.fromTo(this.logoBG, 2, {scale: 1.25, ease: 'Power2.easeOut'}, {scale: 1, ease: 'Power2.easeOut'}, 1)
        this.tl.from(this.cta, 2, {y: -10, autoAlpha: 0, ease: 'Power2.easeOut'}, 1)
        this.tl.play()
    }

    createCircles(){
        let numCircles = 30
        let container = document.getElementById('splashContainer')

        for (let i = 0; i < numCircles; i++){
            let circle = document.createElement('div')
            circle.className = 'splashCircle'
            container.appendChild(circle)
            this.animate(circle)
        }
    }

    animate(circle){

        let x = this.random(this.minX, window.innerWidth);
        let y = this.random(this.minY, window.innerHeight);
        let sizeProportion = this.random(this.minSize, this.maxSize);
        let size = sizeProportion * window.innerWidth
        let delay = this.random(this.minDelay, this.maxDelay);
        let opacity = this.random(this.minOpacity, this.maxOpacity);
        let duration = this.random(this.minDuration, this.maxDuration);
        let color = this.randomColor()

        gsap.set(circle, {
            x: x,
            y: y,
            width: size,
            height: size,
            autoAlpha: opacity,
            scale: 0,
            backgroundColor: color,
        });


        let tween = gsap.to(circle, duration, {
        autoAlpha: 0,
        scale: 1,
        delay: delay,
        onComplete: this.animate,
        onCompleteParams: [circle]
        });

    }

    randomColor(){
        let colorChoices = ['#D60404', '#FFD91B', '#500482']
        let randomIndex = Math.floor(Math.random() * (colorChoices.length))
        let randomColor = colorChoices[randomIndex]
        return randomColor
    }

    random(min, max){
        if (max === null) {
             max = min; min = 0;
        }
        return Math.random() * (max - min) + min;
    }


    render() {

        return (
            <div id="logInContainer">
                <div id="logInLogoContainer" >
                    <div id="logInSVGContainer">
                        <div style={{height: '100%', position: 'absolute', opacity: 0}} ref={div => {this.logoBG = div}}>
                        <Logo1 colorA="white" colorB="white"  />
                        </div>
                        <div style={{height: '100%', position: 'absolute'}} ref={div => {this.logo = div}}>
                        <Logo1 colorA="#D60404" colorB="white" />
                        </div>
                    </div>
                    <div id="lineContainer">
                        <div className="lines" ref={div => {this.line1 = div}} />
                        <div className="lines" ref={div => {this.line2 = div}} />
                        <div className="lines" ref={div => {this.line3 = div}} />
                        <div className="lines" ref={div => {this.line4 = div}} />
                        <div className="lines" ref={div => {this.line5 = div}} />
                        <div className="lines" ref={div => {this.line6 = div}} />
                    </div>
                    
                </div>
                <a href="/api/spotify/logIn" >
                    <button id="spotifyLogInButton" ref={button => {this.cta = button}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="100%"  version="1.1" viewBox="0 0 168 168">
                        <path fill="#1ED760" d="m83.996 0.277c-46.249 0-83.743 37.493-83.743 83.742 0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l0.001-0.004zm38.404 120.78c-1.5 2.46-4.72 3.24-7.18 1.73-19.662-12.01-44.414-14.73-73.564-8.07-2.809 0.64-5.609-1.12-6.249-3.93-0.643-2.81 1.11-5.61 3.926-6.25 31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-0.903-8.148-4.35-1.04-3.453 0.907-7.093 4.354-8.143 30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-0.001zm0.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219-1.254-4.14 1.08-8.513 5.221-9.771 29.581-8.98 78.756-7.245 109.83 11.202 3.73 2.209 4.95 7.016 2.74 10.733-2.2 3.722-7.02 4.949-10.73 2.739z" />
                    </svg>
                        <h1>Spotify Login</h1>
                    </button>
                </a>
                <div id="splashContainer">

                </div>
            </div>

        )
    }
}
