import React from 'react'
const axios = require('axios')
import { BrowserRouter as Redirect } from 'react-router-dom'
import {SongCard} from './SongCard'
import Player from './Player'
import {Loading} from './Loading'
import {
        play,
        createPlaylist,
        addSongToPlaylist,
        savePlaylist
        } from '../../spotifyFunctions'
import {dateLogic} from '../../dateLogic'
import {Logo1} from './Logos/Logo1'
import { gsap } from "gsap";

window.onSpotifyWebPlaybackSDKReady = () => {
    console.log('playback ready')
};

export default class ChartView extends React.Component {

    constructor(props){
        super(props)

        this.spotify = null;
        this.songCards = []

        this.state = {
            date: '',
            songs: [],
            chart: '',
            pageLoaded: false,
            accessToken: undefined,
            deviceID: '',
            nextTrackIndex: 0,
            nextTrackURI: '',
            paused: true,
            currentTrackSpotifyInfo: {},
            currentTrackChartInfo: {},
            playerInitialized: false,
            playlistID: false,
            userProfile: {},
            pageNotFound: false,
            admin: false,
            replaceURI: ''
        }

        this.waitForSpotify = this.waitForSpotify.bind(this)
        this.getSongs = this.getSongs.bind(this)
        this.setupPlayer = this.setupPlayer.bind(this)
        this.getUserInfo = this.getUserInfo.bind(this)
        this.addSongToPlaylist = this.addSongToPlaylist.bind(this)
        this.saveChartAsPlaylist = this.saveChartAsPlaylist.bind(this)
        this.handlePlay = this.handlePlay.bind(this)
        this.findNextPlayableSong = this.findNextPlayableSong.bind(this)
        this.handleNextSong = this.handleNextSong.bind(this)
        this.continuousPlayback = this.continuousPlayback.bind(this)
        this.togglePlayback = this.togglePlayback.bind(this)
        this.replaceURI = this.replaceURI.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.getCookie = this.getCookie.bind(this)

      }

   async componentDidMount() {

    let chart = this.props.match.params.chart.replace(/_/g, ' ')

    this.setState({chart: chart})

    let date = this.props.match.params.week
    const formatedDate = this.formatDate(date)

    //THIS RETRIEVES ACCESS TOKEN
    const accessToken = this.getCookie('accessToken')

    if (accessToken === ''){
        let {data} = await axios.get('api/spotify/timeout')
        window.location.href = data
    }

    //wait for spotify to load player
    await this.waitForSpotify();

    await this.getUserInfo(accessToken)

    let songs = await this.getSongs(date)

    if (songs.length === 0){
        this.setState({pageNotFound: true})
    }

    this.setState({date: formatedDate, songs: songs, accessToken: accessToken, pageLoaded: true })

    this.setupPlayer()

    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

    componentWillUnmount(){
        this.spotify.disconnect()
    }

    waitForSpotify() {

        return new Promise(resolve => {
            if (window.Spotify) {
                resolve(window.Spotify);
            } else {
              window.onSpotifyWebPlaybackSDKReady = () => {
                resolve(window.Spotify);
              };
            }
          });
      }

    async handleContinousPlayback(){
        await this.setState({paused: true}, () => { this.continuousPlayback()})
    }


    setupPlayer() {

        this.spotify = new window.Spotify.Player({
            name: 'Chart Explorer',
            volume: 1,
            getOAuthToken: callback => {
                    callback(this.state.accessToken)
            }
        })

        this.spotify.addListener('ready', ({ device_id }) => {
            
            // console.log('Ready with Device Id: ', device_id)
            this.setState({deviceID: device_id})
        })
        // Not Ready
        this.spotify.addListener('not_ready', ({ device_id }) => {
            console.log('Not ready with device Id: ', device_id)
        })

        //end song listener
        this.spotify.addListener('player_state_changed', async (state) => {

            this.setState({currentTrackSpotifyInfo: state})

            if (
                this.state
                && state.position === 0
                && state.paused
                && state.track_window.previous_tracks.find(x => x.id === state.track_window.current_track.id)
                && state.track_window.current_track.uri !== this.state.nextTrackURI
                // && this.state.paused === false
            )
            {
               await this.handleContinousPlayback()
            }
            else if (state.paused === false){
                this.setState({paused: false})
            }
            else {
                this.setState({paused: true})
            }

        })

        this.spotify.addListener('initialization_error', ({ message }) => { console.log(message); });
        this.spotify.addListener('authentication_error', async ({ message }) => {
           console.log('authentication error', message)
           this.spotify.disconnect()
           let {data} = await axios.get('api/spotify/timeout')
           console.log(data)
           window.location.href = data
        });
        this.spotify.addListener('account_error', ({ message }) => { console.log(message); });
        this.spotify.addListener('playback_error', ({ message }) => { console.log(message); });
        
        try {
            this.spotify.connect()
        }
        catch (e){
            console.log('this is error', e)
        }

    }

    formatDate(date){

        let year = date.slice(0, 4)
        let month = date.slice(5, 7)
        let day = date.slice(8)

        // let formatMonth

        switch (month) {
            case '01':
                month = 'January';
                break
            case '02':
                month = 'February';
                break
            case '03':
                month = 'March';
                break
            case '04':
                month = 'April';
                break
            case '05':
                month = 'May';
                break
            case '06':
                month = 'June';
                break
            case '07':
                month = 'July';
                break
            case '08':
                month = 'August';
                break
            case '09':
                month = 'September';
                break
            case '10':
                month = 'October';
                break
            case '11':
                month = 'November';
                break
            case '12':
                month = 'December';
                break
            default:
                return ''
        }

        if(day[0] === '0'){
            day = day.substring(1)
          }
        
        return month + " " + day + ', ' + year

    }

    async getSongs(date){
        try {
            
            if (this.state.chart === 'Billboard Hot 100'){
                const {data} = await axios.get(`api/songs/week/${date}`)
                return data
            }
            if (this.state.chart === 'Birthday Toppers'){
                const years = ['1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019']

                let results = []
                
                //we have month and day
                let bday = date.slice(-5)

                let birthdayWeeks = years.map((year) => {
                    let searchDate =  bday + '-' + year

                    let chartWeek = dateLogic(searchDate)
                    return chartWeek
                })

                for (const year of birthdayWeeks) {

                    const {data} = await axios.get(`api/songs/birthday/${year}`)
                    results.push(data[0])

                }

                return results
            }
        }
        catch (e){
            console.log(e)
        }
    }

    async getUserInfo(accessToken){
        try {
            const user = await axios.get(`api/spotify/user/${accessToken}`).then(res => {return res.data})

            if (user.admin === true){
                this.setState({admin: true})
            }

            //do we have this user in our database?
            const {data} = await axios.get(`api/users/${user.id}`)

            if (data === false){
                //create a record in the DB
                const {data} = await axios.post('api/users/', user)
                this.setState({userProfile: data, playlistID: data.playlistURI})
            }
            else {
                this.setState({userProfile: data, playlistID: data.playlistURI})
            }

        }
        catch (e){
            let {data} = await axios.get('api/spotify/timeout')
            window.location.href = data
            console.log(e)
        }

    }

    async addSongToPlaylist(uri){

        let playlist = this.state.playlistID

        if (playlist !== 'false'){

            //get the playlist, if the name isn't chart explorer, make a new playlist called chart explorer
            await addSongToPlaylist(uri, playlist, this.state.accessToken)
            window.alert('Track added to Chart Explorer playlist!')
        }
        else {

            const newPlaylist = await createPlaylist(this.state.accessToken, this.state.userProfile.spotifyID)
            this.setState({playlistID: newPlaylist.id})

            //put playlist.id to the playlistURI of the current user
            const info = {playlistID: newPlaylist.id, user: this.state.userProfile.spotifyID, accessToken: this.state.accessToken}

            await axios.put('api/users/', info)

            await addSongToPlaylist(uri, this.state.playlistID, this.state.accessToken)

            window.alert('"Chart Explorer" playlist has been added to your Spotify library. All future saves will be added to the playlist!')
        }

        //else create playlist and add to that playlist
    }

    async saveChartAsPlaylist(){
        //accessToken, user_id, playlistName, uriArray
        let confirmed = confirm("Save current chart as playlist in Spotify Library?")
        let accessToken = this.state.accessToken
        let spotifyID = this.state.userProfile.spotifyID
        let playlistName = `${this.state.chart} - ${this.state.date}`
        let uriArray = this.state.songs.filter(obj => obj.spotifyURI !== 'none').map(obj => obj.spotifyURI)
        
        if (confirmed){
            await savePlaylist(accessToken, spotifyID, playlistName, uriArray )
            alert('Chart Saved to Spotify Library!')
        }

    }

    async continuousPlayback(){
        
        let nextIndex = this.state.nextTrackIndex
        let nextURI = this.state.nextTrackURI

        let nextSongChartInfo = this.state.songs[nextIndex]

        this.setState({currentTrackChartInfo: nextSongChartInfo})
        
        await play(nextURI, this.state.accessToken, this.state.deviceID)

        await this.setState((prevState) => ({...prevState, currentPlayIndex: nextIndex, currentTrackURI: nextURI, paused: false}), () => {this.handleNextSong(this.state.currentPlayIndex)})
    }

    //this should just determine the next song URI to play
    handleNextSong(currentTune){

        let nextTrackIndex = this.findNextPlayableSong(currentTune)

        let nextURI = this.state.songs[nextTrackIndex].spotifyURI

        this.setState((prevState) => ({...prevState, nextTrackIndex: nextTrackIndex, nextTrackURI: nextURI}))
    }

    //this should establish an index point on user interaction with the chart
    async handlePlay(song, accessToken, deviceID, index) {



        await this.setState({currentPlayIndex: index, currentTrackURI: song.spotifyURI, paused: false}, () => {this.handleNextSong(this.state.currentPlayIndex)})

        //this just plays the shit
        play(song.spotifyURI, accessToken, deviceID)

        this.setState({
            currentTrackChartInfo: song,
            playerInitialized: true
        })

    }

    findNextPlayableSong(currentTune){

        let playable = false

        let next = currentTune + 1

        if (next === this.state.songs.length){
            next = 0
        }

        while (playable === false){

            if (this.state.songs[next].spotifyURI !== 'none'){
                playable = true
                return next
            }
            else if (next === this.state.songs.length -1){

                next = 0

            }
            else {
                next++
            }
        }

    }

    async togglePlayback(){
       let playbackState = !this.state.paused
       await this.spotify.togglePlay()
       this.setState({paused: playbackState})
    }

    async replaceURI(song){

        const uri = prompt('Please enter replacement URI:')

        if (uri.indexOf('spotify:track:') === 0 || uri === 'none'){
            let updateSong = {
                song: song.song,
                artist: song.artist,
                uri: uri,
                accessToken: this.state.accessToken,
                user: this.state.userProfile
            }
            const {data} = await axios.put('/api/songs/update', updateSong)

            alert('URI UPDATED, REFRESH PAGE FOR CHANGES TO TAKE EFFECT')
        }
        else {
            console.log('invalid uri')
            alert('INVALID URI')
        }

    }

    render(){
        let chart = this.state.chart
        let date = this.state.date
        let songs = this.state.songs
        let accessToken = this.state.accessToken
        let deviceID = this.state.deviceID
        let currentTrackInfo = this.state.currentTrackChartInfo
        let currentTrackSpotifyInfo = this.state.currentTrackSpotifyInfo
        let playerInitialized = this.state.playerInitialized
        let paused = this.state.paused

        if (this.state.pageNotFound === true){
            //we want to pop the bad page off of the history stack

            return <Redirect push to="/404" />
        }

        return (
            <div id="chartViewContainer" >
                <div id="chartViewHeader">
                    <div id="chart-logo" onClick={() => {
                        this.props.history.push('/home')
                    }}>
                        <Logo1  colorA="white" colorB="#D60404" />
                    </div>
                    {this.state.pageLoaded === true ?
                    <div id="headerInfo">
                        <h1>{chart}</h1>
                        <h2>{date}</h2>
                    </div>
                    : null
                    }
                </div>
                {this.state.pageLoaded === true ?
                <div id="chartComponentContainer">
                    <div id="chartContainer" >
                        <div id="colorOffsetWrapper">
                        <div id="colorOffset" />
                        </div>
                            <div id="songListWrapper">
                            {
                            songs.map(function(song, index) {
                                this.songCards.push(song)
                                return (
                                    <div
                                    key={song.id}
                                    ref={song => {this.songCards[index] = song}}
                                    onMouseEnter={() => {
                                        gsap.to(this.songCards[index], 0.15, {scale: 1.02})
                                    }}
                                    onMouseLeave={() => {gsap.to(this.songCards[index], 0.15, {scale: 1})}}
                                    >
                                        <SongCard

                                        chart={chart}
                                        song={song}
                                        index={index}
                                        accessToken={accessToken}
                                        deviceID={deviceID}
                                        handlePlay={this.handlePlay}
                                        replaceURI={this.replaceURI}
                                        isAdmin={this.state.admin}
                                        />
                                    </div>
                            
                                )
                            }, this)
                            }
                            </div>
                    </div>
                        <Player
                         spotifyInfo={currentTrackSpotifyInfo}
                         chart={chart}
                         chartInfo={currentTrackInfo}
                         skip={this.continuousPlayback}
                         paused={paused}
                         addSong={this.addSongToPlaylist}
                         savePlaylist={this.saveChartAsPlaylist}
                         togglePlayback={this.togglePlayback}
                         initialized={playerInitialized}
                        />

                </div>
                :
                    <Loading />
                }

            </div>
        )
    }
}
