import React from 'react'

export const SaveIcon = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    const styleA = {
        fill: props.color
    }

    const styleB = {
        fill: 'white'
    }

      return (
        <svg width="100%" height="100%" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1.15298,0,0,1.15298,-76.6604,-96.6673)">
                <circle cx="586.878" cy="604.231" r="501.981" style={styleA} />
            </g>
            <g transform="matrix(1.02359,0,0,1.02359,-14.1531,-14.1531)">
                <path d="M475.3,475.3L475.3,237.551C475.3,204.414 502.163,177.551 535.3,177.552L664.7,177.552C697.837,177.551 724.7,204.414 724.7,237.551L724.7,475.3L962.449,475.3C995.586,475.3 1022.45,502.163 1022.45,535.3L1022.45,664.7C1022.45,697.837 995.586,724.7 962.449,724.7L724.7,724.7L724.7,962.449C724.7,995.586 697.837,1022.45 664.7,1022.45L535.3,1022.45C502.163,1022.45 475.3,995.586 475.3,962.449L475.3,724.7L237.551,724.7C204.414,724.7 177.551,697.837 177.552,664.7L177.552,535.3C177.551,502.163 204.414,475.3 237.551,475.3L475.3,475.3Z" style={styleB} />
            </g>
        </svg>
    )
}
